import React from "react"
import { Text } from "@chakra-ui/react"

const Caption = () => {
  return (
    <Text fontSize="xl" ml="4">
      "Not Facebook" membantu Anda terhubung dan berbagi dengan orang-orang
      dalam kehidupan Anda.
    </Text>
  )
}

export default Caption
