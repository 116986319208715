import React from "react"
import { Button } from "@chakra-ui/react"

const SignInButton = () => {
  return (
    <Button mb="4" bg="#1877F2" width="100%" colorScheme="facebook">
      Masuk
    </Button>
  )
}

export default SignInButton
