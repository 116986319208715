import React from "react"
import {
  ChakraProvider,
  HStack,
  Box,
  Flex,
  Spacer,
  Container,
  Divider,
} from "@chakra-ui/react"
import Logo from "./Logo"
import Caption from "./Caption"
import LoginForm from "./LoginForm"
import Warning from "./Warning"
import "./style.css"

const App = () => {
  const logoName = "notfblogo.svg"

  return (
    <ChakraProvider>
      <Flex w="100%" bg="#F0F2F5">
        <Container maxW="1000px" my="20">
          <Box height="560px">
            <HStack direction={["column", "row"]}>
              <Box mr="9">
                <Logo logoName={logoName} />
                <Caption />
              </Box>
              <Spacer />
              <Box>
                <LoginForm />
                <p className="buat-halaman" align="center" fontSize="md" mt="4">
                  <strong>Buat Halaman</strong> untuk selebriti, merek, atau
                  bisnis.
                </p>
              </Box>
            </HStack>
          </Box>
        </Container>
      </Flex>
      <Container maxW="1000px">
        <p className="bahasa">
          Bahasa Indonesia English (UK) Basa Jawa Bahasa Melayu 日本語 العربية
          Français (France) Español 한국어 Português (Brasil) Deutsch
        </p>
        <Divider m="3" />
        <p className="bahasa">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
          mauris elit, dignissim non odio at, commodo imperdiet felis.
          Pellentesque egestas gravida massa ac porta. Nam quis eros tincidunt
          neque sollicitudin dapibus. Class aptent taciti sociosqu ad litora
          torquent per conubia nostra, per inceptos himenaeos. Quisque venenatis
          malesuada tellus ac ultricies. Sed ut dictum nulla. Praesent accumsan
          ornare ornare. Etiam et quam justo. Aenean quis ullamcorper mauris.
          arcu. rardi
        </p>
        <p className="bahasa">NotMeta © 2022</p>
      </Container>
      <Warning />
    </ChakraProvider>
  )
}

export default App
