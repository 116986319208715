import React from "react"
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Text,
} from "@chakra-ui/react"

const Warning = () => {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })
  return (
    <>
      {/* <Button onClick={onOpen}>Open Modal</Button> */}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Peringatan / Attention</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb="5">
              Ini bukanlah halaman asli facebook, ini hanyalah halaman yang saya
              buat untuk latihan meniru user interface facebook menggunakan
              reactjs, mohon tidak memasukan email dan password akun facebook
              anda dalam halaman ini
            </Text>

            <Text as="i">
              This is not the original Facebook page, this is just a page that I
              created to practice creating a Facebook user interface using
              ReactJS, please don't enter your real email and password on this
              page.
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={onClose}>
              Mengerti / Understand
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default Warning
