import React from "react"
import { Button, Center } from "@chakra-ui/react"

const RegisterButton = () => {
  return (
    <Center>
      <Button mb="4" mt="5" bg="#42B72A" width="50%" colorScheme="facebook">
        Buat Akun Baru
      </Button>
    </Center>
  )
}

export default RegisterButton
