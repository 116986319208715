import React from "react"
import { Text, Center, Divider, Box } from "@chakra-ui/react"
import InputText from "./InputText"
import SignInButton from "./SignInButton"
import RegisterButton from "./RegisterButton"

const LoginForm = () => {
  return (
    <Box
      width="400px"
      boxShadow="lg"
      alignItems="left"
      bg="white"
      p="4"
      mt="10"
      rounded="lg"
    >
      <InputText ph="Email atau Nomor Hp" type="text" />
      <InputText ph="Kata Sandi" type="password" />
      <SignInButton />
      <Center>
        <Text fontSize="xs" color="blue">
          Lupa kata sandi ?
        </Text>
      </Center>
      <Divider m="3" />
      <RegisterButton />
    </Box>
  )
}

export default LoginForm
